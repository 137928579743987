.subheader-container {
  overflow-x: hidden;
  display: flex;
  justify-content: center;
}

.subheader {
  background: var(--platform-primary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.subheader-title-div {
  padding: 0px 15px;
  padding-bottom: 30px;
}

.subheader-btn-div {
  width: 100vw;
  margin: auto;
}

.subheader-btn-container {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.subheader-title {
  text-align: center;
  margin-top: 25px;
  color: var(--pure-white);
}

.main-btn-div {
  width: 100%;
  margin: 15px 15px 0px 15px;
  display: flex;
  justify-content: space-between;
}
.main-btn-div.one {
  justify-content: flex-end;
}

.top-btn {
  color: var(--pure-white);
  font-size: 18px;
  word-spacing: 4px;
  padding: 3px 15px;
  border-radius: 10px;
  box-shadow: 0px 5px 15px 15px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.next-question-button {
  border: 0px;
  color: var(--pure-white);
  box-shadow: 0px 2px 6px 0px var(--primary-color-opacity-4);
  font-weight: 500;
  padding: 13px 20px;
}

.next-page-icon,
.previous-page-icon {
  width: 18px;
  height: 18px;
}

.previous-question-button {
  border: 1px solid var(--platform-primary-color);
  color: var(--platform-primary-color);
  padding: 13px 20px;
  background-color: transparent;
  font-weight: 500;
}

.previous-page-icon {
  transform: scaleX(-1);
}

.previous-question-button .disabled {
  padding: 13px 20px;
  background-color: transparent;
  font-weight: 500;
}

.previous-question-button,
.next-question-button {
  font-weight: 500;
  font-size: 18.0741px;
  line-height: 140%;
  border-radius: 10.0412px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
}

.sychart-chat-footer {
  border-top: 1px solid #f4f4f4;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px 10px;
}

.next-button-disabled {
  border-radius: 7.296px;
  color: var(--pure-white);
  background: var(--deselect, #9992a5);
  box-shadow: 0px 2px 6px 0px #9992a5;
}

.next-question-button:disabled {
  border-radius: 7.296px;
  color: var(--pure-white);
  background: var(--deselect, #9992a5);
  box-shadow: 0px 2px 6px 0px #9992a54d;
}

@media (max-width: 568px) {
  .previous-question-button,
  .next-question-button {
    font-size: 14.0741px;
    line-height: 140%;
  }
}

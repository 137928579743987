.offlinePage {
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whoops-text {
  text-align: center;
  font-weight: bold;
  font-size: 25px;
}
.image-area {
  width: 100%;
  max-width: 1041px;
}
.offline-card {
  max-width: 100%;
  padding: 20px;
  box-shadow: none !important;
}
.text {
  text-align: center;
  justify-content: center;
  color: #433a4e;
  font-weight: 700;
}
.button-view {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  }
 
.symptoms-option-button {
  padding: 6px 16px;
  font-size: 13px;
  border-radius: 34px;
  border: 1px solid var(--deselect, #9992a5);
  background: var(--pure-white);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  font-weight: 400;
  width: fit-content;
  min-width: 42%;
  text-align: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .symptoms-option-button {
    padding: 6px 16px;
    font-size: 13px;
    border-radius: 34px;
  }
}

.question-option {
  display: flex;
  flex-wrap: wrap;
  gap: 13px;
  padding: 24px 30px 13px;
}

.lastQ-question-option {
  display: flex;
  flex-wrap: wrap;
  gap: 35px;
  padding: 24px 20px 30px;
}

.slider-question-option {
  display: flex;
  flex-wrap: wrap;
  gap: 13px;
  padding: 40px 20px 13px;
}

/* .none-symptom-option {
  margin: 0px 20px 20px;
} */

html[dir='ltr'] {
  .option-container {
    padding-right: 25px;
  }
}

html[dir='rtl'] {
  .option-container {
    padding-left: 25px;
  }
}

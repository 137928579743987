.sychart-container-root-department {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(210px, 17%));
  grid-gap: 40px;
  justify-content: center;
  margin-bottom: 50px;
}

.sychart-container-box-department {
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.sychart-container-box-department .display-img-department-div {
  width: 100%;
  height: 100%;
  min-width: 210px;
  min-height: 250px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  box-shadow: 0px 2.8788914680480957px 57.57782745361328px 0px #00000029;
  z-index: 1;
  background: var(--pure-white);
  color: #28232e;
  font-size: 16px;
  font-weight: 600;
}

.sychart-container-box-department .department-image {
  width: 100%;
  height: 170px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.sychart-container-box-department .department-title {
  padding: 16px 30px;
  text-transform: capitalize;
}

@-moz-document url-prefix() {
  @media screen and (max-width: 700px) {
    .sychart-container-root-department {
      grid-template-columns: repeat(auto-fit, minmax(220px, 35vh)) !important;
    }
  }
}

@media (max-width: 500px) {
  .sychart-container-root-department {
    grid-template-columns: repeat(auto-fill, minmax(150px, 40%));
    grid-gap: 25px;
    width: 100%;
    height: 100%;
  }

  .sychart-container-box-department .display-img-department-div,
  .sychart-container-box-department .department-image {
    min-width: 150px !important;
    height: 100%;
  }

  .sychart-container-box-department .display-img-department-div {
    min-height: 150px !important;
    border-radius: 10px;
  }

  .sychart-container-box-department .department-image {
    height: 120px !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .sychart-container-box-department .department-title {
    padding: 12px 18px !important;
    font-size: 13px;
  }
}

.custom-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-header {
  padding: 8px 16px;
  background-color: #f0f0f0;
}

.dropdown-options {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: var(--pure-white);
  filter: drop-shadow(0px 0px 8.718232154846191px rgba(0, 0, 0, 0.13));
  z-index: 1;
  height: 200px;
  overflow: auto;
  border-radius: 8px;
  margin-top: 6px;
}

.dropdown-option {
  color: #615c6b;
  font-size: 13px;
  font-weight: 600;
  padding: 8px 16px;
}

.dropdown-option:hover {
  color: #615c6b;
  background: var(--primary-color-opacity-3);
}

/*
.triangle {
  font-size: .5rem;
  position: relative;
  width: 2rem;
  height: 2rem;
  text-align: center;
  margin: 10% auto 0;
  overflow: hidden;
  border-radius: 100%;
  position: absolute;
}

.triangle:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border: solid 2em transparent;
  border-bottom-color: orange;
  border-bottom-width: 3.2em;
  border-top-width: 0;
  margin: -.3em -2em;
} */

/* Base Container */
.chatbot-container {
  display: flex;
  height: 100vh;
  justify-content: center;
  overflow: hidden;
  box-sizing: border-box;
}

.chatbot-container .chatbot {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.message-options-view {
  padding: 1px;
}
.message-option-text {
  font-size: 12px;
  font-weight: 500;
  margin: 0px;
}

.chatbot-container .chatbot .header-chat {
  background-color: var(--pure-white);
  width: 100%;
  height: 65px;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
  gap: 16px;
}

.header-chat .exit-chatbot {
  width: 28px;
  height: auto;
}

.header-chat .name {
  text-transform: uppercase;
  font-size: 18px;
  color: #515151;
}

.header-chat .chatbot-header-logo {
  width: 46px;
  height: auto;
}

.header-chat .icon {
  margin-left: 30px;
  color: #515151;
  font-size: 14px;
}

.header-chat .right {
  position: absolute;
  right: 40px;
}

/* Description Contact */
.desc-contact {
  height: 43px;
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: pre-wrap;
}

/* Discussions */
.discussions .discussion .name,
.discussions .discussion .message {
  margin-left: 20px;
  color: #515151;
}

.discussion .name {
  font-size: 11px;
}

.discussion .message {
  margin: 6px 0 0 20px;
  margin-top: 6px;
  font-size: 9px;
}

/* Chatbot Body */
.chat-body {
  width: 100%;
}

.messages-chat {
  box-sizing: border-box;
  overflow-y: scroll;
  max-height: calc(100dvh - 147px);
  -ms-overflow-style: none;
  padding: 40px 35px 30px;
  scrollbar-width: none;
}

.messages-chat::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

/* Chatbot Response */
.message {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
  gap: 8px;
  max-width: 80%;
}

.bot-message .photo {
  display: block;
  min-width: 40px;
  height: 40px;
  background: var(--pure-white)
    url('https://cdn.mydocsy.com/production/assets/61-docsy-logo-1707249383.png')
    center/contain no-repeat;
  border-radius: 50%;
}

.online {
  position: relative;
  width: 12px;
  height: 12px;
  top: 32px;
  background-color: #32cd32;
  border-radius: 50%;
}

.timer {
  font-size: 11px;
  padding: 3px 8px;
  color: #bbb;
  background-color: var(--pure-white);
  border: 1px solid #e5e5e5;
  border-radius: 15px;
}

.bot-reply {
  width: fit-content;
  max-width: 100%;
  line-height: 1.5;
  white-space: pre-wrap;
  word-break: break-word;
  border-radius: 18px;
  padding: 7px 12px;
  background-color: var(--pure-white);
}

.bot-reply a {
  color: #7a03fe !important;
  cursor: pointer;
}

/* User Message */
.user-message {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-bottom: 16px;
}

.user-message .message {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
  gap: 8px;
  max-width: 80%;
}

.user-message .text {
  padding: 7px 12px;
  line-height: 1.5;
  white-space: pre-wrap;
  word-break: break-word;
  text-align: start;
  border-radius: 18px;
  background: linear-gradient(
    138deg,
    rgb(160, 106, 255) 0%,
    rgb(122, 48, 254) 100%
  );
  color: var(--pure-white);
}

.user-message .response {
  margin-left: auto;
}

.user-message .time,
.time {
  font-size: 10px;
  color: #000;
  margin-bottom: 10px;
}

/* Chatbot Footer */
.footer-chat {
  position: fixed;
  bottom: 0;
  z-index: 2;
  box-sizing: border-box;
  width: 100%;
  min-height: 70px;
  background: var(--pure-white);
  padding: 12px 30px;
}

.footer-chat .icon {
  margin-left: 30px;
  color: #c0c0c0;
  font-size: 14pt;
}

.footer-chat .send {
  color: var(--pure-white);
  background-color: #4f6ebd;
  position: absolute;
  right: 50px;
  padding: 12px;
  border-radius: 50px;
  font-size: 14px;
}

.footer-chat .name {
  margin-left: 20px;
  text-transform: uppercase;
  font-size: 13pt;
  color: #515151;
}

.footer-chat .right {
  position: absolute;
  right: 40px;
}

.footer-chat input::placeholder {
  color: #c0c0c0;
  font-size: 13pt;
}

.chat-input-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.chat-input-form input {
  height: 30px;
  font-size: 18px;
  overflow-y: hidden;
  border-radius: 32px;
  padding: 0.875rem 1rem 0.875rem 1rem;
  width: 100%;
  background: #e9edef;
  border: 0;
}

.send-message-icon {
  width: 26px;
  height: 26px;
}

/* Miscellaneous */
.markdown-link {
  color: #4f6ebd;
}

.bot-reply .sep-line {
  background-color: gray;
  height: 1px;
  margin: 5px 0;
}

.bot-reply .link-btn-container .icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1%;
  flex-direction: row;
  width: 100%;
}

.bot-reply .link-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.link-btn-text {
  color: #7a03fe;
  margin-left: 5px;
  cursor: pointer;
}

/* Media Queries */
@media (max-width: 600px) {
  .messages-chat {
    padding: 16px;
  }

  .footer-chat {
    padding: 12px 16px;
  }

  .chat-input-form input {
    font-size: 16px;
    padding: 0.5rem 1rem;
    line-height: 1.4;
  }

  .user-message .message,
  .message {
    max-width: calc(100% - 35px);
  }

  .user-message .message {
    max-width: calc(100% - 50px);
  }
}

/* List Styles */
.chatbot ul,
.chatbot ol {
  line-height: 0.25;
}

.chatbot li {
  line-height: 1.4;
}

/* Direction Handling */
html[dir='ltr'] {
  .bot-reply {
    border-bottom-left-radius: 4px;
    text-align: start;
  }

  .bot-reply ol,
  .bot-reply ul {
    margin-left: 16px;
  }

  .user-message .text {
    border-bottom-right-radius: 4px;
  }

  .time,
  .user-message .time {
    margin-left: 48px;
  }

  .online {
    left: 26px;
  }

  .timer {
    margin-left: 15%;
  }
}

html[dir='rtl'] {
  .bot-reply {
    border-bottom-right-radius: 4px;
    text-align: start;
  }

  .exit-chatbot {
    transform: rotateY(180deg);
  }
  .bot-reply ol,
  .bot-reply ul {
    margin-right: 24px;
  }

  .user-message .text {
    border-bottom-left-radius: 4px;
  }

  .time,
  .user-message .time {
    margin-right: 48px;
  }

  .online {
    right: 26px;
  }

  .timer {
    margin-right: 15%;
  }

  .send-message-icon {
    width: 26px;
    height: 26px;
    transform: rotate(180deg);
  }
}

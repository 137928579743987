*:focus {
  outline: none;
}
.hint-modal {
  width: 100%;
}

.hint-bubble {
  font-size: 16px;
  padding: 20px;
  width: 260px;
  border-radius: 15px;
  background: var(--pure-white);
  outline: none;
  min-height: 160px;
  overflow-wrap: break-word;
  transform: rotate(3.632deg);
  position: relative;
}

.bacground-model-line {
  border: 1px solid var(--pure-white);
  border-radius: 15px;
  transform: rotate(-3.632deg);
  position: relative;
}

.hint-title {
  color: rgb(40, 35, 46);
  text-decoration: none;
}

.hint-description {
  align-self: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  display: flex;
  color: rgb(67, 58, 78);
  font-size: 13px;
  line-height: 173%;
}

.close-popup {
  position: absolute;
  right: 2%;
  top: 10px;
}

.ar-close-popup {
  position: absolute;
  left: 2%;
  top: 10px;
}

.hint-text-box {
  padding: 40px 28px;
  text-align: center;
}

.popup-icon-Background {
  background-image: linear-gradient(145deg, #fbcb51 0%, #ff8947 100%);
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
  width: 56px;
  height: 56px;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  top: -13%;
  left: 130px;
  z-index: 1;
  transform: rotate(3.632deg);
}

.departheader-container {
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  top: 0px;
  right: 0px;
  position: sticky;
  z-index: 2;
}

.departheader {
  background: var(--platform-primary-background);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  height: 78px;
  justify-content: center;
  width: 100%;
}

.departheader-title-div {
  padding: 0px 15px;
}

.departheader-btn-div {
  width: 100vw;
}

.departheader-btn-container {
  display: flex;
  justify-content: space-between;
}

.departheader-title {
  text-align: center;
  color: var(--pure-white);
}

.departheader-title-div {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-btn-div {
  width: 100%;
  margin: 15px 15px 0px 15px;
  display: flex;
  justify-content: space-between;
}

.main-btn-div.one {
  justify-content: flex-end;
}

.top-btn {
  color: var(--pure-white);
  font-size: 18px;
  word-spacing: 4px;
  /* padding: 3px 15px; */
  border-radius: 10px;
  box-shadow: 0px 5px 15px 15px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.departheader-backIcon {
  width: 20px;
  height: 20px;
}

.container-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-self: center;
}

.container-coloumn {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 7px;
}

.display-image {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

span.body-part-list-btn {
  background: var(--pure-white);
  width: 150px;
  height: 50px;
  font-size: 20px;
  border-radius: 15px;
}

span.body-part-list-btn:hover {
  background: var(--platform-primary-color);
  color: var(--pure-white);
}

/* Doctor Chat */
.doctor-chat {
  display: flex;
  justify-content: left;
}

.doctor-chat-profile {
  max-width: 60px;
  margin-right: 12px;
  border-radius: 50%;
  width: 44px;
  height: 44px;
}

.doctor-question-background-shadow {
  margin-top: 13px;
  max-width: 60%;
  width: fit-content;
  border-radius: 22px;
  text-align: center;
  position: relative;
  border-top-left-radius: 0px !important;
}

.doctor-box {
  padding: 15px 20px;
  background: var(--primary-color-opacity-1);
  border-top-left-radius: 10px;
  border-bottom: 1.6px solid var(--platform-primary-color);
  width: fit-content;
  border-radius: 22px;
  text-align: center;
  position: relative;
  font-size: 15px;
  border-top-left-radius: 0px !important;
}

/* Patient Chat */
.patient-chat {
  display: flex;
  align-items: flex-start;
  justify-content: right;
  margin-right: 16px;
  flex-direction: row-reverse;
}

.patient-reply-box {
  padding: 15px 20px;
  background: #f1f1f1;
  border-radius: 22px;
  text-align: center;
  position: relative;
  font-size: 15px;
  border-top-right-radius: 0px !important;
  border-bottom: 1.6px solid #9992a5;
}

.patient-reply-question-background-shadow {
  margin-top: 13px;
  max-width: 60%;
  width: fit-content;
  border-radius: 22px;
  text-align: center;
  position: relative;
  border-top-right-radius: 0px !important;
}

/* General Styles */
.sychart-message-arrow {
  margin-right: -1.6px;
  margin-top: 13px;
}

.answer-sychart-message-arrow {
  margin-left: -1.6px;
  margin-top: 13px;
}
.doctor-question-background-shadow .bg-white {
  border-top-left-radius: 0px !important;
}
.patient-reply-question-background-shadow .bg-white {
  border-top-right-radius: 0px !important;
}

.doctor-multiple-question {
  color: var(--platform-primary-color);
  font-family: DM Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-align: flex-start;
}

.doctor-question,
.patient-reply-question {
  text-align: start;
  font-family: DM Sans;
  font-size: 16px;
  color: #312a3d;
  line-height: 140%;
  font-weight: 400;
  word-break: break-word;
}

.doctot-question-video,
.doctot-question-image {
  max-height: 150px;
  max-width: 160px;
  object-fit: cover;
  border-radius: 10px;
  margin-top: 10px;
}

.bg-white {
  background: #ffffff;
}

.hint-bulb-div {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 24px;
  padding-top: 13px;
}

/* Arabic Doctor Chat */
.ar-doctor-chat {
  display: flex;
  justify-content: right;
}

.ar-doctor-chat-profile {
  max-width: 60px;
  margin-left: 16px;
  border-radius: 50%;
  width: 44px;
  height: 44px;
}

.ar-doctor-box {
  padding: 15px 20px;
  background: var(--primary-color-opacity-1);
  border-top-right-radius: 10px;
  border-bottom: 1.6px solid var(--platform-primary-color);
  width: fit-content;
  border-radius: 22px;
  text-align: center;
  position: relative;
  font-size: 15px;
  border-top-right-radius: 0px !important;
}

.ar-doctor-question-background-shadow {
  margin-top: 13px;
  max-width: 60%;
  width: fit-content;
  border-radius: 22px;
  text-align: center;
  position: relative;
  border-top-right-radius: 0px !important;
}

/* Arabic Patient Chat */
.ar-patient-chat {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  flex-direction: row-reverse !important;
}

.ar-patient-reply-box {
  padding: 15px 20px;
  background: #f1f1f1;
  width: fit-content;
  border-radius: 22px;
  text-align: center;
  position: relative;
  font-size: 15px;
  border-top-left-radius: 0px !important;
  border-bottom: 1.6px solid #9992a5;
}

.ar-patient-reply-question-background-shadow {
  margin-top: 13px;
  max-width: 60%;
  width: fit-content;
  border-radius: 22px;
  text-align: center;
  position: relative;
  border-top-left-radius: 0px !important;
}

/* General Arabic Styles */
.ar-sychart-message-arrow {
  margin-left: -1.6px;
  margin-top: 13px;
  transform: scaleX(-1);
}

.ar-answer-sychart-message-arrow {
  margin-right: -1.6px;
  margin-top: 13px;
  transform: scaleX(-1);
}
.hint-bulb-size {
  height: 26px;
  width: 26px;
}

.ar-doctor-question-background-shadow .ar-bg-white {
  border-top-right-radius: 0px !important;
}
.ar-patient-reply-question-background-shadow .ar-bg-white {
  border-top-left-radius: 0px !important;
}
.ar-bg-white {
  background: white;
}
.ar-hint-bulb-div {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 25px;
  padding-top: 13px;
}

@media (max-width: 500px) {
  /* Non-Arabic */

  .doctor-box,
  .bg-white,
  .doctor-question-background-shadow,
  .patient-reply-box,
  .patient-reply-question-background-shadow {
    border-radius: 16px;
  }

  @media (max-width: 361px) {
    .doctor-box,
    .bg-white,
    .doctor-question-background-shadow {
      border-radius: 16px;
      width: 97%;
    }
  }

  @media (min-width: 300px) {
    .hint-bulb-div {
      align-items: center;
      display: flex;
      justify-content: center;
      padding-left: 15px !important;
      padding-top: 13px;
    }
  }

  @media (max-width: 350px) {
    .doctot-question-video,
    .doctot-question-image {
      max-width: 160px;
      object-fit: fill;
      border-radius: 10px;
      margin-top: 10px;
      aspect-ratio: 2;
    }
  }

  @media (max-width: 350px) {
    .hint-bulb-size {
      height: 18px;
      width: 18px;
    }
  }

  .sychart-message-arrow,
  .answer-sychart-message-arrow {
    margin-right: -1.4px;
    width: 16px;
    height: 16px;
    min-width: 16px;
  }

  .doctor-chat-profile,
  .patient-reply-chat-profile {
    max-width: 40px;
    margin-right: 12px;
    width: 30px;
    height: 30px;
  }
  .doctor-question,
  .patient-reply-question {
    font-size: 14px;
  }

  /* Arabic */
  .ar-doctor-box,
  .ar-bg-white,
  .ar-doctor-question-background-shadow,
  .ar-patient-reply-box,
  .ar-patient-reply-question-background-shadow {
    border-radius: 16px;
  }

  .ar-sychart-message-arrow,
  .ar-answer-sychart-message-arrow {
    margin-right: -1.4px;
    width: 16px;
    height: 16px;
    min-width: 16px;
  }

  .ar-doctor-chat-profile {
    max-width: 40px;
    margin-left: 12px;
    width: 30px;
    height: 30px;
  }
}

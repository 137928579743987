.option-button-box,
.option-was-select {
  border-radius: 16px 16px 0px 0px;
  background: var(--pure-white);
  padding: 0px 20px;
  position: sticky;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.sychart-view .sychart-mobile-view {
  width: 100%;
  max-width: 500px;
  position: relative;
  height: 100%;
  box-shadow: 0px 6px 5px 0px rgba(0, 0, 0, 0.09),
    0px 0px 6px 0px rgba(0, 0, 0, 0.15) inset;
}

.character-count {
  margin-top: 40px;
  position: absolute;
  bottom: -12px;
  right: -8px;
  font-size: 13px;
  font-weight: lighter;
}

.comment-text {
  width: 100%;
  border-radius: 10px;
  border: 1px solid var(--pure-white);
  background-color: #f4f4f4;
  overflow-y: auto;
  position: relative;
  max-height: 200px;
}

@media (max-height: 700px) and (max-width: 420px) {
  .comment-text {
    max-height: 100px;
  }
}

.sychart-view .sychart-scroll-view {
  width: 100%;
  max-width: 500px;
  position: relative;
  height: 100%;
  overflow: hidden;
  box-shadow: 0px 6px 5px 0px rgba(0, 0, 0, 0.09),
    0px 0px 6px 0px rgba(0, 0, 0, 0.15) inset;
}

.sychart-view .sychart-mobile-view::-webkit-scrollbar {
  width: 0.5em;
}

.sychart-view .sychart-mobile-view::-webkit-scrollbar-track {
  background: transparent;
}

.sychart-view .sychart-mobile-view::-webkit-scrollbar-thumb {
  background: transparent;
}

.sychart-view {
  display: flex;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
}

.option-was-select {
  box-shadow: 0px -3.648184061050415px 24.625240325927734px 0px var(--primary-color-opacity-4);
}

.option-button-box {
  box-shadow: 0px -3.648184061050415px 24.625240325927734px 0px rgba(0, 0, 0, 0.08);
}

.sychart-option-button {
  padding: 8px 16px;
  font-size: 13px;
  border-radius: 34px;
  border: 1px solid var(--deselect, #9992a5);
  background: var(--pure-white);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  font-weight: 400;
  line-height: 140%;
  gap: 6px;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: 42%;
}

.sychart-option-left-button {
  padding: 8px 16px;
  font-size: 13px;
  border-radius: 34px;
  border: 1px solid var(--deselect, #9992a5);
  background: var(--pure-white);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  font-weight: 400;
  line-height: 140%;
  gap: 6px;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  min-width: 42%;
}

.other-btn-sychart-active {
  background-color: var(--primary-color-opacity-2);
}

.other-btn-sychart-disable {
  background-color: none;
}
.select-sychart-option-button{
  padding: 8px 16px;
    font-size: 13px;
    border-radius: 34px;
    border: 1px solid var(--platform-primary-color);
    background:var(--primary-color-opacity-2);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    font-weight: 400;
    line-height: 140%;
    gap: 6px;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width: 42%;
}

.yes-no-style {
  padding: 10px 16px;
  font-size: 13px;
  border-radius: 34px;
  border: 1px solid var(--deselect, #9992a5);
  background: var(--pure-white);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  font-weight: 400;
  min-width: 100px;
  line-height: 140%;
  gap: 6px;
  align-items: center;
  justify-content: center;
  width: 42%;
  text-align: left;
}

@media (max-width: 768px) {
  .sychart-option-button {
    font-size: 12px;
  }
}

.question-option {
  display: flex;
  flex-wrap: wrap;
  gap: 13px;
  padding: 24px 30px 32px;
  /* max-height: 30vh; */
  overflow-y: auto;
  height: auto;
  justify-content: space-between;
}

@media (max-height: 700px) {
  .question-option {
    max-height: 18vh;
    overflow-y: auto;
  }
}

@media (min-height: 701px) {
  .question-option {
    max-height: 30vh;
    overflow-y: auto;
  }
}

.option-selected {
  background: var(--primary-color-opacity-1);
  box-shadow: 0px 1px 4px 0px var(--primary-color-opacity-2);
  color: var(--platform-primary-color);
  border-color: var(--platform-primary-color);
}

.disabled-tickIcon path {
  fill: #00000066;
  min-height: 66px;
}

.question-option {
  scrollbar-width: thin;
}

.question-option::-webkit-scrollbar {
  width: 4px;
}

.question-option::-webkit-scrollbar-thumb {
  background-color: rgb(207, 203, 203);
  border-radius: 8px;
}

.question-option::-webkit-scrollbar-thumb:hover {
  background-color: rgb(199, 194, 194);
}

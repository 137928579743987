.typingIndicatorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 27px;
  width: 80px;
}

.typingIndicatorBubble {
  display: flex;
  align-items: center;
  justify-content: center;
}

.typingIndicatorBubbleDot {
  width: 8px;
  height: 8px;
  margin-right: 4px;
  background: var(--platform-primary-background);
  border-radius: 50%;
  animation-name: bounce;
  animation-duration: 1.3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.typingIndicatorBubbleDot:first-of-type {
  margin: 0px 4px;
}

.typingIndicatorBubbleDot:nth-of-type(2) {
  animation-delay: 0.15s;
}

.typingIndicatorBubbleDot:nth-of-type(3) {
  animation-delay: 0.3s;
}

@keyframes bounce {
  0%,
  60%,
  100% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-8px);
  }
}

.process-bar {
  height: 18px;
  width: 100%;
  margin: 0px 20px;
  background-color: var(--primary-color-opacity-1);
  border-radius: 50px;
  border: 3px solid var(--pure-white);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.fill-processbar {
  height: 100%;
  width: 4.1%;
  background: var(--platform-primary-background);
  border-radius: 50px;
  text-align: right;
  transition: width 1s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 3px;
  position: absolute;
  top: 0;
  left: 0;
}

.label-processbar {
  width: 14px;
  height: 14px;
  margin-right: 2.3px;
  border-radius: 50%;
  color: var(--pure-white);
  font-weight: bold;
  background-color: var(--pure-white);
  box-shadow: 0px 0px 0px 0px var(--pure-white);
  animation: 3s ripple ease infinite;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.arabic-process-bar {
  height: 18px;
  width: 100%;
  margin: 0px 20px;
  background-color: var(--primary-color-opacity-1);
  border-radius: 50px;
  border: 3px solid var(--pure-white);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.arabic-fill-processbar {
  height: 100%;
  width: 4.2%;
  background: var(--platform-primary-background);
  border-radius: 50px;
  transition: width 1s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
}

.arabic-label-processbar {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  color: var(--pure-white);
  font-weight: bold;
  background-color: var(--pure-white);
  box-shadow: 0px 0px 0px 0px var(--pure-white);
  margin-left: 2.3px;
  animation: 3s ripple ease infinite;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

/* Animation */
@keyframes ripple {
  0%,
  60% {
    box-shadow: 0px 0px 10px 2px var(--pure-white);
  }

  30%,
  100% {
    box-shadow: 0px 0px 0px 0px var(--pure-white);
  }
}

@media (max-width: 500px) {
  .arabic-label-processbar {
    margin-left: 1px;
  }
  .label-processbar {
    margin-right: 1.7px;
  }
}

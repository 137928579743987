::-webkit-file-upload-button {
  background: #405672;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 10px;
  padding: 4px 20px;
}
*:focus {
  outline: none;
}

.Error404 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 8%;
}
.Error404Image {
  width: 100%;
  max-width: 700px;
  margin: 25px;
}
.Errortext {
  font-size: 24px;
  text-align: center;
}
.Back-to-homeButoon {
  max-width: 330px;
  margin: 25px 0px;
  width: 100%;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--pure-white);
  background: var(--platform-primary-color);
  font-weight: 800;
  font-size: 16px;
  border: 0px;
  cursor: pointer;
  border-radius: 8px;
}
.Back-to-homeButoon:hover {
  transform: scale(1.01);
}
@media (max-width: 500px) {
  .Back-to-home {
    max-width: 230px;
  }
}

.model-back-button,
.model-next-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 0 0 var(--primary-color-opacity-6);
  }

  50% {
    box-shadow: 0 0 30px 0 var(--primary-color-opacity-4);
  }

  100% {
    box-shadow: 0 0 0 0 var(--primary-color-opacity-6);
  }
}

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300;9..40,400;9..40,500;9..40,600;9..40,700&family=Inter:wght@300;400;600;700&family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,400&display=swap');

.model-button-icon-container,
.model-button-icon-container-disabled,
.model-previous-button-icon-container,
.model-previous-button-icon-container-disabled {
  border-radius: 50px;
  padding: 13px 22px;
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
  position: relative;
}

.model-button-icon-container {
  background: var(--platform-primary-background);
  animation: glow 2s infinite ease-in-out;
}

.model-previous-button-icon-container {
  border: 1px solid var(--platform-primary-color);
}

.model-button-icon-container-disabled,
.model-previous-button-icon-container-disabled {
  background-color: var(--pure-white);
  border: 1px solid #cdcdcd;
}

.model-button-icon-container-disabled svg path,
.model-previous-button-icon-container-disabled svg path {
  stroke: #cdcdcd;
}

.nova-model {
  background-color: var(--pure-white);
  max-width: 962px;
  width: 75%;
  height: fit-content;
  max-height: 80%;
  flex-direction: column;
  padding: 0 20px;
  position: relative;
  outline: none;
  box-shadow: 0 0 111px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
}

.model,
.modelBox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.model-next-button-title {
  color: var(--pure-white);
  font-weight: 500;
  font-size: 18.7951px;
  line-height: 140%;
}

.model-previous-button-title {
  color: var(--platform-primary-color);
  font-weight: 500;
  font-size: 18.7951px;
  line-height: 140%;
}

.modelIndex {
  font-size: 28px;
}

.model-indicator {
  gap: 20px;
  align-self: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  min-height: 120px;
}

.modelBox {
  background-color: var(--pure-white);
  max-width: 962px;
  width: 80%;
  height: 599px;
  background-image: url(/src/docsy-components/Resources/Images/caraousalBackground.png);
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  padding: 0 20px;
  position: relative;
  outline: none;
  box-shadow: 0 0 111px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 40px 0px;
}

.image-container {
  display: flex;
  justify-content: center;
}

.models-image {
  width: 180px;
  height: 120px;
}

.title {
  font-size: 24px;
  font-weight: 900;
  color: #28232e;
  line-height: 36px;
  width: 55%;
  max-width: 580px;
  margin-top: 25px;
}

.nova-model .title {
  width: 75%;
  color: #28232e;
}

.model-description {
  font-size: 12px;
  color: #28232e;
  font-weight: 500;
  margin-top: 25px;
  text-align: left;
}

.nova-model .model-description {
  width: 75%;
  max-width: 700px;
}

.model-close-button {
  background: var(--platform-primary-background);
  box-shadow: var(--primary-color-opacity-2) 0 6.62069px 13.2414px;
  border-radius: 41px;
  color: var(--pure-white);
  font-size: 19.8621px;
  line-height: 140%;
  padding: 12px 20px;
  width: 280px;
  border: 0;
  /* margin-top: 40px; */
}

.model-close-button:hover {
  background: linear-gradient(
    100.2deg,
    var(--primary-color-opacity-9) -151.85%,
    var(--primary-color-opacity-8) 113.48%
  );
}

.nova-model .carousel-root {
  max-height: 70%;
}

.nova-model .carousel.carousel-slider {
  height: 100%;
}
.carousel.carousel-slider .control-arrow {
  display: none;
}

.model-description-content ul {
  /* font-size: 14px; */
  /* font-family: 'Bold', sans-serif !important; */
  display: flex;
  flex-direction: column;
  gap: 5px;

  /* list-style: disc; */
}

.lastModel {
  max-height: 300px;
}

.previousModel {
  height: 375px;
}
@media (min-width: 508px) {
  .slide {
    margin-bottom: 32px !important;
  }
}

@media (max-width: 508px) and (min-height: 698px) {
  .lastModel {
    max-height: 100% !important;
    overflow: hidden !important;
  }
}

@media (max-height: 698px) and (max-width: 508px) {
  .lastModel {
    max-height: 230px !important;
    overflow: auto !important;
  }
  .carousel .slider-wrapper.axis-horizontal .slider,
  .carousel .slider-wrapper {
    height: 100%;
  }
  .nova-model .carousel.carousel-slider {
    height: 475px;
  }
  .model-close-button {
    margin-top: 0px !important;
  }
}

@media (max-width: 508px) {
  div .models-image {
    height: 83px !important;
  }
  .nova-model .model-description {
    max-width: 100% !important;
    width: 100% !important;
  }

  div .model-description-content {
    padding: -1px 12px 0px 20px !important;
  }

  .nova-model .title {
    width: 95% !important;
    font-size: 22px !important;
    line-height: 138.023% !important;
  }
  div .model-description-content {
    line-height: 138.023% !important;
  }

  .slide {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    gap: 8px;
    margin-bottom: 0px !important;
  }
  .model-buttons {
    margin-top: 0px !important;
    margin-bottom: 24px !important;
  }
  .nova-model .carousel-root {
    margin-top: -30px !important;
  }
  .model-indicator {
    gap: 8px;
    min-height: 65px;
  }
  .div .model-description-content {
    font-size: 16px !important;
  }
}

@media (min-width: 500px) {
  .previousModel {
    max-height: 240px;
  }
  .model-close-button {
    background: var(--platform-primary-background);
    box-shadow: var(--primary-color-opacity-2) 0 6.62069px 13.2414px;
    border-radius: 41px;
    color: var(--pure-white);
    font-size: 19.8621px;
    line-height: 140%;
    padding: 12px 20px;
    width: 280px;
    border: 0;
    margin-top: 40px;
  }

  .model-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: -40px;
  }
}
@media (max-height: 790px) {
  .section {
    margin-top: 24px !important;
    padding: 0px !important;
  }
  div .nova-model {
    max-width: 962px;
    width: 75%;
    max-height: 90%;
    min-height: auto;
    justify-content: space-between;
  }
  div .model-description-content {
    padding: 0px 20px;
    overflow-y: auto;
    text-align: left;
    font-size: 15px !important;
  }
  div .model-description-content.lastModel {
    overflow-y: auto !important;
    max-height: 189px !important;
  }
}
@media (max-height: 800px) {
  .nova-model .carousel-root {
    max-height: 80%;
  }

  .nova-model .title {
    width: 90%;
    margin-top: 10px;
  }

  .nova-model .model-description {
    max-width: 90%;
    width: 90%;
    margin-top: 12px;
  }

  .nova-model .section {
    margin: 0;
    padding: 5px;
  }

  .model-description {
    position: relative;
    display: flex;
  }

  .model-description-scroll {
    position: relative;
    display: flex;
    max-height: 200px;
  }

  .model-description-content {
    flex: 1;
    padding: 18px;
    overflow: auto;
    text-align: left;
  }

  .image-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .nova-logo-modal {
    width: 60px !important;
    object-fit: contain;
    position: absolute;
    top: 20px !important;
    left: 20px !important;
  }

  .nova-arabic-logo-modal {
    width: 60px !important;
    object-fit: contain;
    position: absolute;
    top: 20px !important;
    right: 20px !important;
  }
}
@media (max-width: 800px) {
  .section {
    margin: 60px 0 0;
  }

  .nova-model {
    max-width: 962px;
    width: 75%;
    min-height: auto;
    justify-content: space-between;
  }

  .image-container {
    display: flex;
    justify-content: center;
  }

  .model-close-button {
    font-size: 0.93rem;
    padding: 10px 20px;
    width: 180px;
    margin-top: 30px;
  }

  .title {
    margin-top: 20px;
    font-size: 17px;
    line-height: 148.023%;
    width: 60%;
  }

  .nova-model .title {
    width: 90%;
  }

  .model-description {
    font-size: 15px;
    line-height: 150.523%;
  }

  .nova-model .model-description {
    max-width: 90%;
    width: 90%;
  }

  .modelIndex {
    font-size: 1.25rem;
  }

  .models-image {
    height: 95px !important;
  }

  .model-next-button-title,
  .model-previous-button-title {
    font-size: 0.87rem;
  }

  .mobile-back {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .modelBox {
    height: 80%;
    max-height: 560px;
  }

  .image-container {
    display: flex;
    justify-content: center;
    margin-top: 22px;
  }

  .nova-logo-modal {
    width: 72px !important;
    object-fit: contain;
    position: absolute;
    top: 20px !important;
    left: 20px !important;
  }

  .nova-arabic-logo-modal {
    width: 60px !important;
    object-fit: contain;
    position: absolute;
    top: 20px !important;
    right: 20px !important;
  }
}
@media (max-height: 930px) and (min-height: 790px) {
  .section {
    margin-top: 34px !important;
    padding: 0px !important;
  }

  div .nova-model {
    max-width: 962px;
    width: 75%;
    min-height: auto;
    justify-content: space-between;
  }
  div .model-description-content {
    padding: 0px 20px;
    overflow: auto;
    text-align: left;
  }
}
@media (max-width: 1169px) {
  .title {
    margin-top: 30px;
    font-size: 1.38rem;
    line-height: 22px;
    width: 65%;
  }
  .nova-model .title {
    font-size: 1.5rem;
  }
  div .model-description-content div div {
    font-size: 1.3rem;
  }

  .nova-model .title {
    width: 80%;
  }

  .section {
    margin-top: 20px;
  }

  .model-description {
    margin-top: 15px;
    font-size: 0.875rem;
    width: 60%;
  }

  .nova-model .model-description {
    width: 80%;
  }

  .models-image {
    height: 135px;
  }

  .modelIndex {
    font-size: 1.3125rem;
  }

  .model-next-button-title,
  .model-previous-button-title {
    font-size: 0.88rem;
  }

  .model-close-button {
    font-size: 1.125rem;
    padding: 10px 20px;
    width: 240px;
    /* margin-top: 40px; */
  }
}

.model-description-content ul {
  /* font-size: 12px; */
  /* font-family: 'Bold', sans-serif !important; */
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* list-style: disc; */
}

.lastModel {
  height: 150px;
}

.previousModel {
  height: 220px;
}

@media (max-width: 500px) {
  .previousModel,
  .lastModel {
    overflow: hidden !important;
    height: fit-content !important;
  }
}

@media (min-width: 500px) {
  .model-close-button {
    background: var(--platform-primary-background);
    box-shadow: var(--primary-color-opacity-3) 0 6.62069px 13.2414px;
    border-radius: 41px;
    color: var(--pure-white);
    font-size: 19.8621px;
    line-height: 140%;
    padding: 12px 20px;
    width: 280px;
    border: 0;
    margin-top: 0px;
  }
}

@media (max-height: 790px) {
  .section {
    margin-top: 24px !important;
    padding: 0px !important;
  }
}
@media (max-height: 930px) and (min-height: 790px) {
  .section {
    margin-top: 34px !important;
    padding: 0px !important;
  }

  div .nova-model {
    max-width: 962px;
    width: 75%;
    min-height: auto;
    justify-content: space-between;
  }
  div .model-description-content {
    padding: 0px 20px;
    overflow: auto;
    text-align: left;
  }
}

@media (max-height: 800px) {
  .nova-model .carousel-root {
    max-height: 80%;
  }

  .nova-model .title {
    width: 90%;
    margin-top: 10px;
  }

  .nova-model .model-description {
    max-width: 90%;
    width: 90%;
    margin-top: 12px;
  }

  .nova-model .section {
    margin: 0;
    padding: 5px;
  }

  .model-description {
    position: relative;
    display: flex;
  }

  .model-description-scroll {
    position: relative;
    display: flex;
    max-height: 200px;
  }

  .model-description-content {
    flex: 1;
    padding: 18px;
    overflow: auto;
    text-align: left;
  }

  .image-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .nova-logo-modal {
    width: 60px !important;
    object-fit: contain;
    position: absolute;
    top: 20px !important;
    left: 20px !important;
  }

  .nova-arabic-logo-modal {
    width: 60px !important;
    object-fit: contain;
    position: absolute;
    top: 20px !important;
    right: 20px !important;
  }
}

@media (max-width: 800px) {
  .section {
    margin: 60px 0 0;
  }

  .image-container {
    display: flex;
    justify-content: center;
  }

  .model-close-button {
    font-size: 0.93rem;
    padding: 10px 20px;
    width: 180px;
    margin-top: 30px;
  }

  .title {
    margin-top: 20px;
    font-size: 17px;
    line-height: 148.023%;
    width: 60%;
  }
  div .model-description-content div div {
    font-size: 1.3rem !important;
  }

  .nova-model .title {
    width: 90%;
  }

  .model-description {
    font-size: 15px;
    line-height: 150.523%;
  }

  .nova-model .model-description {
    max-width: 90%;
    width: 90%;
    /* margin-top: 20px; */
  }

  .modelIndex {
    font-size: 1.25rem;
  }

  .models-image {
    width: 80px;
    height: 75px;
    bottom: 80px;
  }

  .model-next-button-title,
  .model-previous-button-title {
    font-size: 0.87rem;
  }

  .mobile-back {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .modelBox {
    height: 80%;
    max-height: 560px;
  }

  .image-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .nova-logo-modal {
    width: 72px !important;
    object-fit: contain;
    position: absolute;
    top: 20px !important;
    left: 20px !important;
  }

  .nova-arabic-logo-modal {
    width: 60px !important;
    object-fit: contain;
    position: absolute;
    top: 20px !important;
    right: 20px !important;
  }
}

.nova-logo-modal {
  width: 100px;
  object-fit: contain;
  position: absolute;
  top: 20px;
  left: 20px;
}

.nova-arabic-logo-modal {
  width: 60px !important;
  object-fit: contain;
  position: absolute;
  top: 20px !important;
  right: 20px !important;
}

.modal-left-background-element {
  width: 130px;
  object-fit: contain;
  position: absolute;
  top: 8px;
  left: 0px;
  transform: scale(-1);
}
.modalRightBackgroundElement {
  width: 130px;
  object-fit: contain;
  position: absolute;
  top: 0px;
  right: 0px;
}

.modalLeftBackgroundElement {
  width: 130px;
  object-fit: contain;
  position: absolute;
  bottom: 0px;
  left: 0px;
  transform: scaleX(-1);
}

.arModalRightBackgroundElement {
  width: 130px;
  object-fit: contain;
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.arModalLeftBackgroundElement {
  width: 130px;
  object-fit: contain;
  position: absolute;
  top: 0px;
  left: 0px;
  transform: scaleX(-1);
}

.model-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.carousel .slide img {
  width: auto !important;
}

.carousel img {
  width: 100% !important;
}

.model-description-content {
  overflow-y: auto; /* Enable vertical scrolling */
  scrollbar-width: thin; /* Set the width of the custom scrollbar */
  scrollbar-color: #7a30fe #eaeaea; /* Set the color of the thumb and track */
}

/* Define the custom scrollbar styles for WebKit browsers */
.model-description-content::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.model-description-content::-webkit-scrollbar-thumb {
  background-color: var(--platform-primary-color); /* Color of the thumb */
  border-radius: 4px; /* Rounded corners for the thumb */
}

.model-description-content::-webkit-scrollbar-thumb:hover {
  background-color: #5124b5; /* Color of the thumb on hover */
}

.model-description-content::-webkit-scrollbar-track {
  background-color: #eaeaea; /* Color of the track */
  border-radius: 4px;
}

.model-description-content div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.model-description-content div div {
  font-size: 21px;
  font-weight: 900;
  color: #28232e;
  margin-top: 12px;
  margin-bottom: 12px;
}
.model-previous-button-title,
.model-next-button-title {
  font-weight: 800;
}
.model-close-button {
  font-weight: 900;
}

@media (max-width: 360px) {
  .nova-model .title {
    font-size: 20px !important;
  }
  div .model-description-content div div {
    font-size: 1.1rem !important;
  }
  div .model-description-content {
    font-size: 14.5px !important;
  }
}

@media (max-height: 680px) {
  .model-indicator {
    margin-top: 0px !important;
  }
  .slide {
    justify-content: flex-start !important;
  }
  .nova-model .title {
    margin-bottom: 0px !important;
  }

  div .nova-model {
    max-height: 95%;
  }

  .arModalLeftBackgroundElement,
  .arModalRightBackgroundElement,
  .modalRightBackgroundElement,
  .modalLeftBackgroundElement {
    width: 100px;
  }
}
@media (max-width: 340px) {
  .nova-model .title {
    font-size: 18px !important;
  }
  div .model-description-content div div {
    font-size: 1rem !important;
  }
  div .model-description-content {
    font-size: 14px !important;
  }

  div .model-description-content {
    padding: 0px 0px 0px 17px;
  }
}
@media (max-width: 450px) {
  div .models-image {
    height: 75px !important;
    margin-top: 30px;
  }
}

/* common button with loader */
.commonbtn {
  color: var(--pure-white);
  width: 100%;
  padding: 12px;
  margin: 20px 0px;
  background-color: var(--platform-primary-color);
  border: 0px;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
  border-radius: 8px;
}
.commonbutton {
  position: relative;
  background: #009579;
  outline: none;
  cursor: pointer;
  display: inline-block;
  padding: 0 24px;
  min-width: 120px;
  height: 48px;
  border-radius: 24px;
  background-color: var(--platform-primary-color);
  box-shadow: 0 2px 5px rgba(26, 188, 156, 0.25);
  border: none;
  text-align: center;
  vertical-align: middle;
  font-size: 16px;
  line-height: 48px;
  color: var(--pure-white);
  transition: all 0.25s ease-in-out;
}

.commonbutton:active {
  background: var(--platform-primary-color);
}

.commonbuttonbutton__text {
  font-size: 20px;
  font-weight: bold;
  color: var(--pure-white);
  transition: all 0.2s;
}

.commonbutton--loading .commonbutton__text {
  visibility: hidden;
  opacity: 0;
}

.commonbutton--loading::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: var(--pure-white);
  border-radius: 50%;
  animation: commonbutton-loading-spinner 1s ease infinite;
}

@keyframes commonbutton-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.doctor-status {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: flex-start;
}

.item-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.status {
  font-size: 12px;
  line-height: 14px;
  color: var(--pure-white);
}

.doctor-name {
  font-weight: 700;
  font-size: 18px;
  color: var(--pure-white);
  line-height: 22px;
}

.subheader-discription {
  display: flex;
  align-items: center;
  gap: 8px;
}

.sychartSubHeader {
  padding: 16px 28px;
  overflow-x: hidden;
  background: var(--platform-primary-background);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  position: sticky;
  top: 0px;
  z-index: 99;
}

.subheaderFeature {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.header-tool {
  display: flex;
  align-items: center;
  color: var(--pure-white);
}

.subheader-doctor-profile {
  width: 54.613px;
  height: 54.613px;
  border-radius: 50%;
}

.hint-bulb {
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  display: flex;
}

.hint-bulb p {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 568px) {
  .subheader-doctor-profile {
    width: 40px;
    height: 40px;
  }

  .sychartSubHeader {
    padding: 15px 20px 12px;
  }

  .status {
    font-size: 12px;
    line-height: 12px;
  }

  .doctor-name {
    font-size: 15px;
    line-height: 140%;
  }

  .previousIcon {
    width: 8px;
  }
}

.restart-btn-header {
  padding: 10px 11px;
  font-weight: 700;
  line-height: 130%;
}

.exit-btn-header,
.restart-btn-header {
  border-radius: 70px;
  border: 1px solid var(--pure-white, #fff);
  background: var(--platform-primary-background);
  box-shadow: 0px 2px 5px 0px var(--primary-color-opacity-3);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--pure-white, #fff);
  gap: 5px;
  font-size: 12px;
}

.exit-btn-header {
  padding: 10px 15px;
  font-weight: 700;
  line-height: 130%;
  font-size: 12px;
}
